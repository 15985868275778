
import {
  defineComponent,
  computed,
  onMounted,
  watch,
  nextTick,
  ref,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { jwtDecode } from "jwt-decode";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
// removing right drawer until we have a use case
// import KTHelpDrawer from "@/layout/extras/HelpDrawer.vue";
// import KTToolButtons from "@/layout/extras/ToolButtons.vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import JwtService, {
  getOrganization,
  getRehab,
  getUser,
  saveRehab,
  saveUser,
} from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    KTUserMenu,
    // removing right drawer until we have a use case
    // KTHelpDrawer,
    // KTToolButtons,
    KTLoader,
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const currentComponent = ref(null);
    const rehanChanging = ref(false);
    const isLoaded = ref(false);

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(async () => {
      ApiService.setHeader();

      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ path: "/open-id-login" });
      }

      await store.dispatch("GETALLENUMS");

      await store.dispatch("GETCURRENTORGANIZATION");
      await store.dispatch("GETALLREHABS");
      const selectedRehabId = getRehab();
      if (selectedRehabId) {
        const rehabs = store.getters.allRehabs;
        const rehab = rehabs.find((item) => {
          return item.rehabId == selectedRehabId;
        });
        if (rehab) {
          saveRehab(rehab?.rehabId);
          ApiService.setRehab();
          store.commit("SETCURRENTREHAB", rehab);
        }
      }
      if (!getRehab()) {
        const rehabs = store.getters.allRehabs;
        if (rehabs.length > 0) {
          let rehab = rehabs[0];
          const token = JwtService.getToken();
          if (token) {
            const decoded = jwtDecode(token);
            const rehabId = decoded["rehabs_assigned"];
            const selRehab = rehabs.find((item) => {
              return item.rehabId == rehabId;
            });
            if (selRehab) rehab = selRehab;
          }
          saveRehab(rehab?.rehabId);
          ApiService.setRehab();
          store.commit("SETCURRENTREHAB", rehab);
        }
      }
      isLoaded.value = true;

      nextTick(() => {
        reinitializeComponents();
      });

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ path: "/open-id-login" });
        }

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    watch(
      () => store.getters.isUserAuthenticated,
      () => {
        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ path: "/open-id-login" });
        }
      }
    );


    function rehabChanged(rehabId) {
      rehanChanging.value = true;
    }

    return {
      toolbarDisplay,
      rehanChanging,
      currentComponent,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      rehabChanged,
      isLoaded,
    };
  },
});
