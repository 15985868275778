
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useStore } from "vuex";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentOrganization = computed(() => {
      return store.getters.organization;
    });
    function selectRoute(item) {
      if (item.path == "back") {
        router.back();
      } else {
        router.push(item.path);
      }
    }
    return {
      toolbarWidthFluid,
      currentOrganization,
      selectRoute,
    };
  },
});
