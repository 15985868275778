import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "header-menu align-items-stretch",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "header-menu",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_header_menu_mobile_toggle",
  "data-kt-place": "true",
  "data-kt-place-mode": "prepend",
  "data-kt-place-parent": "{default: '#kt_body', lg: '#kt_header_nav'}"
}
const _hoisted_2 = {
  class: "menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch",
  id: "#kt_header_menu",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "d-flex align-items-center text-dark fw-bolder my-1 fs-3"
}
const _hoisted_4 = {
  key: 1,
  class: "d-flex align-items-center text-dark fw-bolder my-1 fs-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentOrganization)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.currentOrganization.name), 1))
        : (_openBlock(), _createElementBlock("h1", _hoisted_4, " No organization selected "))
    ])
  ]))
}