const DocMenuConfig = [
  {
    pages: [
      {
        heading: "My Homepage",
        route: "/homePage",
        fontIcon: "fa-house-user",
      },
      {
        heading: "Service Management",
        route: "/serviceManagement/",
        fontIcon: "fa-folder-minus",
      },
      // {
      //    heading: "Claim Submission",
      //    route: "/claimSubmission",
      // },
      {
         heading: "Claim Management",
         route: "/claimManagement",
         fontIcon: "fa-tasks",
       },
      {
        heading: "Payment Management",
        route: "/Payments/",
        fontIcon: "fa-hand-holding-usd",
      },
      {
        heading: "Patient Accounts",
        route: "/patientAccounts",
        fontIcon: "fa-hospital-user",
      },
      {
        heading: "Statements",
        route: "/statements",
        fontIcon: "fa-receipt",
      },
      //  {
      //     heading: "Settings & Config",
      //     route: "/devtools/devconfig",
      //     svgIcon: "media/icons/duotune/art/art003.svg",
      //     fontIcon: "bi-app-indicator",
      // },
      // {
      //   heading: "Unapplied Credit",
      //   route: "/unappliedCredit",
      // },
      //{
      //  heading: "Reports",
      //  route: "/reports",
      //  fontIcon: "fa-file-alt",
      //},
      {
        heading: "Settings",
        route: "/Settings",
        fontIcon: "fa-cog",
        // sub: [
        //   {
        //     heading: "Providers",
        //     route: "/settings/providersPage",
        //     svgIcon: "media/icons/duotune/art/art003.svg",
        //     fontIcon: "bi-app-indicator",
        //   },
        //   {
        //     heading: "Facilities",
        //     route: "/settings/facilitiesPage",
        //     svgIcon: "media/icons/duotune/art/art003.svg",
        //     fontIcon: "bi-app-indicator",
        //   },
        //   {
        //     heading: "Service Master",
        //     route: "/settings/serviceMasterPage",
        //     svgIcon: "media/icons/duotune/art/art003.svg",
        //     fontIcon: "bi-app-indicator",
        //   },
        //   {
        //     heading: "Claim Settings",
        //     route: "/settings/claimSettingsPage",
        //     svgIcon: "media/icons/duotune/art/art003.svg",
        //     fontIcon: "bi-app-indicator",
        //   },
        //   {
        //     heading: "Clearinghouse Settings",
        //     route: "/settings/clearinghouseSettingsPage",
        //     svgIcon: "media/icons/duotune/art/art003.svg",
        //     fontIcon: "bi-app-indicator",
        //   },
        // ],
      },
      // {
      //   sectionTitle: "Unapplied Credit",
      //   route: "/unappliedCredit",
      //   sub: [
      //     {
      //       heading: "Reports",
      //       route: "/reports",
      //       svgIcon: "media/icons/duotune/art/art002.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //     {
      //       heading: "Settings",
      //       route: "/settings",
      //       svgIcon: "media/icons/duotune/art/art002.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //   ],
      // },
      // {
      //   sectionTitle: "POC Legacy",
      //   route: "/",
      //   sub: [
      //     {
      //       heading: "Organization Settings",
      //       route: "/organizations",
      //       svgIcon: "media/icons/duotune/art/art002.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //     {
      //       heading: "Organizations List",
      //       route: "/organizationsList",
      //       svgIcon: "media/icons/duotune/art/art001.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //     {
      //       heading: "Patient Accounts",
      //       route: "/patientsList",
      //       svgIcon: "media/icons/duotune/art/art001.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //     {
      //       heading: "Encounters Management",
      //       route: "/ecountersList",
      //       svgIcon: "media/icons/duotune/art/art001.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //     {
      //       heading: "Claim Management",
      //       route: "/claimsList",
      //       svgIcon: "media/icons/duotune/art/art001.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //     {
      //       heading: "Batches",
      //       route: "/batchQueuePage",
      //       svgIcon: "media/icons/duotune/art/art001.svg",
      //       fontIcon: "bi-app-indicator",
      //     },
      //     {
      //       heading: "*Claim Inspect",
      //       route: "/waystarFieldsPage",
      //       svgIcon: "media/icons/duotune/art/art001.svg",
      //       fontIcon: "bi-app-indicator",
      //     },

      //     {
      //       sectionTitle: "Dictionaries",
      //       route: "/dictionary",
      //       sub: [
      //         {
      //           heading: "Patients",
      //           route: "/dictionary/patients",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //         {
      //           heading: "Patient Payers",
      //           route: "/dictionary/patientPayers",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //         {
      //           heading: "Providers",
      //           route: "/dictionary/providers",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //         {
      //           heading: "Facilities",
      //           route: "/dictionary/facilities",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //         {
      //           heading: "Companies",
      //           route: "/dictionary/rehabs",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //         {
      //           heading: "Encounters",
      //           route: "/dictionary/encounters",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //       ],
      //     },
      //     {
      //       sectionTitle: "[DEV] Tools",
      //       route: "/DevTools",
      //       sub: [
      //         {
      //           heading: "Settings & Config",
      //           route: "/devtools/devconfig",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //         {
      //           heading: "Claims",
      //           route: "/devtools/devclaims",
      //           svgIcon: "media/icons/duotune/art/art003.svg",
      //           fontIcon: "bi-app-indicator",
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
];

export default DocMenuConfig;
